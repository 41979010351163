<template>

    <div class="wrapContentIndex wrapContentAccount">
        <div class="Account">
            <!--        <MenuBarSub title="จัดการบัญชี"></MenuBarSub>-->
            <!--        <MenuBarUnbox title="จัดการบัญชี"></MenuBarUnbox>-->
            <FloatButtomRightPanel  :callChatWithAdmin="showChatWithAdminNumber"/>
            <div class="contentOverlay contentOverlaySidebar" :class="{'popupProvider': isHideProvider}">
                <div class="btn-close-popupAllgame" @click="ClosePopupProvider()"></div>
                <div class="contentListProviderLeft menuStyle">
                    <div class="contentListProviderLeftInfo">
                        <h3>เมนู</h3>
                        <MenuLeft :is_slidebar="true"></MenuLeft>
                    </div>
                </div>
            </div>
            <FloatButtomRightPanel  :callChatWithAdmin="showChatWithAdminNumber"/>
            <div class="wrapContentIndexTitle">
                <button class="btn " @click="goHome()"><i class="fas fa-chevron-left"></i></button>
                <div class="bx-mobile" v-if="false">
                    <div class="hamburger-lines" @click="OpenListProvider()">
                        <span class="line line1"></span>
                        <span class="line line2"></span>
                        <span class="line line3"></span>
                    </div>
                </div>
                <h3>บัญชีธนาคาร</h3>
            </div>
            <div class="navTopDesktop">
                <MenuNavTop @showMenuNav="showMenuNav"  @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"></MenuNavTop>
            </div>
            <MenuBar :callMenu="menuNumber" :callDWMenu="showDepositWithdrawNumber" :goHome="is_go_home"></MenuBar>
            <div class="box-img hide">
                <div class="box-user-img"><img src="@/assets/images/icon/set-icon/icon-acc-manage.png"/></div>
                <div class="box-user-detail">

                    <strong>บัญชีธนาคาร</strong>
                    <!--                <small>Account Management</small>-->
                </div>
            </div>
            <div class="wrapContentIndexInfo">
                <div class="contentFlexIndex">
                    <div class="contentFlexIndexLeft active">
                        <MenuLeft></MenuLeft>
                    </div>
                    <div class="contentFlexIndexRight">
                        <div class="container">
                            <div class="content-page">
                                <div class="content-page-spin">

                                    <div class="container-fluid-game container-fluid-game-spin">
                                        <div class="wrap-content-games">
                                            <UserAccount></UserAccount>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="navButtonMobile">
                    <MenuNav  @showMenuNav="showMenuNav"  @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"></MenuNav>
                </div>
            </div>

        </div>
    </div>

</template>
<script>
    import MenuBar from '@/components/MenuBar'
    // import MenuBarUnbox from '@/components/menu-bar/Unbox'
    import UserAccount from '@/components/user/Account'
    import FloatButtomRightPanel from '@/components/FloatButtomRightPanel'
    import MenuLeft from '@/components/MenuLeft'
    import MenuNav from '@/components/MenuNav'
    import MenuNavTop from '@/components/MenuNavTop'
    // import MenuBarSub from '@/components/menu-bar/Sub'

export default {
    name: 'Account',
    components: {
        FloatButtomRightPanel,
        UserAccount,
        // MenuBarUnbox,
        MenuBar,
        MenuLeft,
        MenuNav,
        MenuNavTop,
    },
    data() {
        return {
            is_go_home: '',
            showChatWithAdminNumber:0,
            showDepositWithdrawNumber:0,
            menuNumber :0,
            isHideProvider: false,
            isHideListGame: true,
            isHideListGameContent: false,
        }
    },
    methods: {
        goHome() {
                this.is_go_home='Index';
                let self = this;
                setTimeout(()=>{
                    self.is_go_home='';
                },1000)
                
        },
        showChatWithAdmin(){
                this.showChatWithAdminNumber=1;
                let self =this;
                setTimeout(()=>{
                    self.showChatWithAdminNumber=0;
                },100);
                
        },
        showDepositWithdrawModal(){
                this.showDepositWithdrawNumber=1;
                let self =this;
                setTimeout(()=>{
                    self.showDepositWithdrawNumber=0;
                },100);
                
        },
        showMenuNav(menuNumber){
                this.menuNumber = menuNumber;
                let self =this;
                setTimeout(()=>{
                    self.menuNumber=0;
                },1000);
        },
        OpenListProvider() {
            this.isHideProvider = true;
        },
        ClosePopupProvider() {
            this.isHideProvider = false;
        },
        OpenListAllGame(){
            this.isHideListGame = false;
            this.isHideListGameContent = true;
        },
        CloseListAllGame() {
            this.isHideListGame = true;
            this.isHideListGameContent = false;
        },

        toIndexPopularPage() {
            this.$router.push({name: 'IndexPopular'})
        },
        toIndexCasinoPage() {
            this.$router.push({name: 'IndexCasino'})
        },
        toIndexSlotPage() {
            this.$router.push({name: 'IndexSlot'})
        },
        toIndexFishingPage() {
            this.$router.push({name: 'IndexFishing'})
        },
        toIndexSportPage() {
            this.$router.push({name: 'IndexSport'})
        },
        toIndexLottoPage() {
            this.$router.push({name: 'IndexLotto'})
        },
        toIndexUnboxPage() {
            this.$router.push({name: 'IndexUnbox'})
        },
    }
}
</script>
<style src="@/assets/css/unbox.css" scoped></style>
<style>
    .Account .MenuBar.box-all-games{display: none!important}
    .Account span.box-txt-back:before{
        content: '\e88a';
        font: 25px "Material Icons";
        margin-right: 5px;
    }
    .Account .box-img{
        top: 4px!important;
        left: 45px!important;
        height: 45px;
        display: flex;
        align-items: center;
        padding: 0!important;
    }
    /*.Account .menu-list-top span:nth-child(1),*/
    /*.Account .menu-list-top span:nth-child(2){display: none!important}*/
    .Account span.icon-AccountPage{display: none}
    @media (orientation: landscape) {
        /*.Account .MenuBar.box-all-games{display: block!important}*/
        /*.container-fluid-game-spin{overflow-x: hidden;height: calc(100vh - 50px);}*/
    }
</style>
<style scoped>
.Account .page-content {
    /*height: calc(100vh - 50px);*/
    /*overflow-y: auto;*/
}
.Account .box-img{
    top: 0!important;
    left: 45px!important;
    height: 45px!important;
    display: flex;
    align-items: center;
    font-size: 20px;
    padding: 0!important;
}
.Account .content-page-spin{position: relative}

.wrap-menu-top{background-image: none;}
.Account{
    background-image: linear-gradient(
            135deg
            , #F372D4 10%, #c765f0 100%);
}
.Account .content-page{
    padding: 0;
    position: relative;
}
/*.Account .container-fluid-game-spin{overflow-x: hidden;height: calc(100vh - 116px);}*/
.Account .container-fluid-game-spin{overflow: hidden;height: 100vh}


.content-page {
    background-color: #fff;
    border-radius: 20px 20px 0 0;
    padding: 5px 0;
}

.box-user-img, .box-user-img img {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.box-img, .box-menu-img {display: flex;color: #fff;font-family: "Athiti", sans-serif;}
.box-img {padding: 0 15px;flex: 3;height: unset;}
.box-img small {display: block;margin-top: -5px;}
.box-user-detail{padding-left: 10px;}
.box-user-img{display: none;}
</style>